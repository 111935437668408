@import '_bits';
@import '_vendor';
@import '_buttons';

@font-face {
	font-family: 'proxima';
	src: url('fonts/proximanova-regular-webfont.ttf')  format('truetype');
}

@font-face {
	font-family: 'proxima-light';
	src: url('fonts/proximanova-light-webfont.ttf')  format('truetype');
}

@font-face {
	font-family: 'proxima-semibold';
	src: url('fonts/proximanova-semibold-webfont.ttf')  format('truetype');
}

@include keyframes(rotate) {
	0% {
		@include prefix( transform, rotateZ(0deg) );
	}
	100% {
		@include prefix( transform, rotateZ(360deg) );
	}
}

html {
	overflow: hidden;
	padding: 0;
	margin: 0;
}

body {
	position: absolute;
	width:100%;
	height:100%;
}

.fill {
	position: absolute;
	top: 0; bottom: 0;
	left: 0; right: 0;
}

.disabled {
	opacity: 0.4;
	background: $light-color;
}

.center-contents {
	display: -webkit-box;
	@include prefix-arg( display, flex );
    @include prefix( justify-content, center );
    @include prefix( align-items, center );
}

.justify-contents {
	display: -webkit-box;
	@include prefix-arg( display, flex );
    @include prefix( justify-content, center );
}

.grabbing, .grabbing * {
	cursor: -webkit-grabbing !important;
	cursor: grabbing !important;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ellipsis:focus {
	text-overflow: clip;
}

input {
	outline-color: transparent;
    outline-style: none;
}

*:not([draggable]) {
	-webkit-user-drag: none;
}

*:not(:focus):not(.edit) {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.edit {
	outline: none;
	border: none;
	resize: none;
	cursor: text;

	&:focus, &:focus * {
		-webkit-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		-o-user-select: text;
		user-select: text;
	}
}

.selectable, .selectable * {
	-webkit-user-select: text !important;
	-moz-user-select: text !important;
	-ms-user-select: text !important;
	-o-user-select: text !important;
	user-select: text !important;
}



.placeholder {
    opacity: .4;
}

.hidden {
	display: none !important;
}

.not-visible {
	visibility: hidden !important;
}

.visible {
	visibility: visible !important;
}

.depth-1 {
	/*box-shadow:
		0px 1px 3.5px rgba(54,47,42,.15),
		0px -1px 1.5px rgba(54,47,42,.1);*/
}
.sliding {
	z-index: 1000;
	box-shadow:
		0px 3px 3px rgba(0,0,0,.16),
		0px 3px 3px rgba(0,0,0,.23);
}
.dragging {
	box-shadow:
		0px 10px 10px rgba(0,0,0,.19),
		0px 6px 3px rgba(0,0,0,.23);
	box-sizing: border-box;


	.checklist-item {
		display: -webkit-box;
		@include prefix-arg( display, flex );
	    @include prefix( align-items, center );

		span {
			margin-top: 4px;
			&.done {
				text-decoration: line-through;
			}
		}

		.checkbox {
		    @include prefix( flex-shrink, 0 );
			height: 16px; width: 16px;
			border-radius: 2px;
			border: solid $dark-color 1px;
			margin-right: 8px;
			margin-top: 5px;
			svg {
				fill: $dark-color;
				width: 14px; height: 14px;
			}
		}
	}

}

ul.no-list, ul.dragging, ul.freeform-dragging {
	list-style: none;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

// .edit *::moz-selection{ background-color: $inverse-field-select !important; }
// .edit *::webkit-selection{ background-color: $inverse-field-select !important; }
// .edit *::selection{ background-color: $inverse-field-select !important; }

.edit::-moz-selection, .edit *::-moz-selection {
	background: $field-select !important;
}
.edit::-webkit-selection, .edit *::-webkit-selection {
	background: $field-select !important;
}
.edit::selection, .edit *::selection {
	background: $field-select !important;
}

.inverse.edit::-moz-selection, .inverse.edit *::-moz-selection {
	background: $inverse-field-select !important;
}
.inverse.edit::-webkit-selection, .inverse.edit *::-webkit-selection {
	background: $inverse-field-select !important;
}
.inverse.edit::selection, .inverse.edit *::selection {
	background: $inverse-field-select !important;
}

.pdf-export {
	font-family: 'proxima-semibold';
	font-size: 1.5em;
	color: $dark-color;
	margin-top: 20px;
	margin-left: 50px;
	display: block;
}

.page-not-found {
	margin: 0;
	background: $board-background repeat top left;
	font-family: 'proxima-semibold';
	color: $dark-color;

	section {
		flex-direction: column;

		svg { fill: $action-color; }
		h1 { font-size: 3em; }
		p { margin-top: 0; margin-bottom: 5px; }
		a { color: $action-color; }
	}

}

/* temp, see https://github.com/ractivejs/ractive/issues/1964 */
	.title.empty:before {
		content: attr(data-empty-type) ' Title';
		font-family: 'proxima-light';
		color: $light-color;
	}
	.title.empty:focus:before {
		content: attr(data-empty-type) ' Title';
		font-family: 'proxima-light';
		color: $light-color;
	}

	.label:focus::selection, .value:focus::selection {
		background: $field-select;
	}

	.inverse.label:focus::selection, .inverse.value:focus::selection {
		background: $inverse-field-select;
	}

	.label.empty:not(:focus):before {
		content: 'Label';
	}
.label.empty:focus:before {
		content: '';
	}

	menu {
		menuitem {
			&:not(.cardIcons) {
					opacity: $menu-opacity;
			}
			&:not(.cardIcons):hover, &.cardIcons {
					opacity: $hover-menu-opacity !important;
			}
		}
	}

	/* fun value prompts */

	.value.placeholder:before {
		content: 'add text';
	}

	.value:focus.placeholder:before {
		content: '';
	}

	.link-text .value.placeholder:before {
		content: '';
	}
	
	.link-text .edit.placeholder:before {
		content: 'link title';
	}

	



	/* .value.url:not(:focus).placeholder:before {
		content: 'enter link';
		opacity: 0.8 !important;
		font-weight: 900;
		border-radius: 4px;
	}*/
	//leave the placeholder with focus too
	.value.url.placeholder:before {
		content: 'enter link';
		opacity: 0.8 !important;
		font-weight: 900;
		border-radius: 4px;
	}


	.value.edit.url {
		//border-bottom: 1px solid gray;
		padding: 3px;
	}

	// .value:not(:focus).empty1:before {
	// 	content: 'i\'m lonely w/o text';
	// }

	// .value:not(:focus).empty2:before {
	// 	content: 'fuzzy-wuzzy was a bear';
	// }

	// .value:not(:focus).empty3:before {
	// 	content: 'type here now, please i\'m begging you';
	// }

	// .value:not(:focus).empty4:before {
	// 	content: 'meow';
	// }

	// .value:not(:focus).empty5:before {
	// 	content: 'hmmmm....';
	// }

	// .value:not(:focus).empty6:before {
	// 	content: 'something\'s missing here';
	// }
	
	/* .jsPlumb features */

	//Make strokes GLOW for visibility in hihghlight mode

	.svg.highlightglow {
		stroke:red;
  		stroke-width:13;
	}


/* end temp */
