@mixin prefix($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument};
}

@mixin prefix-arg($name, $argument) {
  #{$name}: -webkit-#{$argument};
  #{$name}: -ms-#{$argument};
  #{$name}: -moz-#{$argument};
  #{$name}: -o-#{$argument};
  #{$name}: #{$argument};
}

@mixin transition-transform($transition...) {
    $max: length($transition);
    $transitions: '';

    @for $i from 1 through $max {
        $transitions: #{$transitions + nth($transition, $i)};

        @if $i < $max {
            $transitions: #{$transitions + ", "};
        }
    }

    -webkit-transition: -webkit-transform $transitions;
    -ms-transition: -ms-transform $transitions;
    -moz-transition: -moz-transform $transitions;
    -o-transition: -o-transform $transitions;
    transition: transform $transitions;
}

@mixin transition($transition...) {
    $max: length($transition);
    $transitions: '';

    @for $i from 1 through $max {
        $transitions: #{$transitions + nth($transition, $i)};

        @if $i < $max {
            $transitions: #{$transitions + ", "};
        }
    }

    -webkit-transition: $transitions;
    -ms-transition: $transitions;
    -moz-transition: $transitions;
    -o-transition: $transitions;
    transition: $transitions;
}

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}


@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-ms-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}
