
/*********************
VARIABLES
*********************/

$menu-image-color: rgba(88, 79, 76, 0.2);
$menu-background:  rgb(94, 86, 80);
$menu-image-hover:  rgba(88, 79, 76, .4);
$menu-inverse-image-color: rgba(255, 255, 255, 0.375);

$menu-opacity: 0.8;
$hover-menu-opacity: 1.0;
$selected-opacity: 1.0;
$inactive-menu-opacity: 0.5;

$selected-color: rgb(255, 247, 237);
$light-color: rgb(191, 187, 184);
$dark-color: rgb(92, 84, 79);
$header-color:  rgb(88, 79, 76);
$white-color: white;
$action-color: rgb(247, 148, 32);
$sea-color: rgb(91, 170, 209);
$fuego-color: rgb(242, 104, 66);
$badge-color: rgb(215,77,60);
$storm-color: rgb(8,124,110);
$apple-color: rgb(215, 212, 85);
$onboard-bg-color: rgb(59,145,153);
$tangerine-color: rgb(245, 148, 52);

$light-text-color: rgb( 138,132,130 );

$field-select: rgba(255, 255, 255, .45);
$field-hover: rgba(255,255,255,0.3);
$inverse-field-select: rgba(248, 205, 85, .24);
$inverse-field-hover: rgba(248, 205, 85, .12);

$loader: url('images/loader.svg');
$cardsmith-logo: url('images/logo.svg');
$board-background: url('images/bkground.png');
$account-background: url('images/grey_v4.png');

$header-height: 70px;

/*********************
BOARDS
*********************/
$board-width: 204px;
$board-height: 110px;
$board-background-color: $white-color;
$board-border-radius: 3px;


/*********************
ALERTS
*********************/

  swal-overlay {
  background-color: rgba(0,0,0,.2);
}
.swal-modal {
  background-color: #FFFFFF;
  border: 1px solid #D2CECB;
  width: 440px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  
}
.swal-title {
  font-family: 'proxima-semibold';
  font-size: 22px;
  text-align:left;
  color: #584f4c;
  margin: 35px 30px 0px 35px;
  padding: 10px 0px 0px 0px;
  
}
.swal-text {
  font-family: 'proxima-light';
  font-size: 16px;
  color: #584f4c;
  line-height: 1.5;
  margin: 0px 30px 0px 35px;
  padding: 0px 0px 0px 0px;
  display: block;
  text-align: left;
}
.swal-small-text {
  font-family: 'proxima-light';
  font-size: 12px;
  color: #584f4c;
  line-height: 1.5;
  margin: 0px 30px 0px 35px;
  padding: 0px 0px 0px 0px;
  display: block;
  text-align: left;
}

.swal-footer {
  overflow: hidden;
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding: 20px 25px 35px 20px;
}
.swal-button {
  font-family: 'proxima-semibold';
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 3px;
  background-color: rgb(247,148,32  );
  color: white;
  text-align: center;
  

}

.swal-button:focus {
    outline-style: none;
    box-shadow: none;
}

.swal-button:not([disabled]):hover {
  background-color: rgba(247,148,32, .70);

}
.swal-button:active {
 background-color: rgb(247,148,32  );
}
.swal-button--danger {
  background-color: rgb(242, 104, 66); 
}
.swal-custom-wide 
{
   width: 550px !important;
}
.swal-custom-button-grey {
  background-color: rgb(193,189,188);
} 
.bottom-border {
  border-bottom: solid $light-color 1px;
  width: 100%;
  margin-top: 10px;
  
  height: 1px;
}


/*********************
BREAKPOINTS
*********************/

 @mixin breakpoint($point) {
   @if $point == desktop {
     @media (min-width: 70em) { @content ; }
  }
   @else if $point == laptop {
     @media (min-width: 64em) { @content ; }
  }
    @else if $point == tablet {
     @media (max-width: 50em) { @content ; }
  }
   @else if $point == phablet {
     @media (min-width: 37.5em)  { @content ; }
  }
  @else if $point == mobileonly {
     @media (max-width: 37.5em)  { @content ; }

  }
}
