// @import '_bits';


$menu-image-color: rgba(88, 79, 76, 0.4);
$menu-background:  rgb(94, 86, 80);
$menu-image-hover:  rgba(88, 79, 76, .6);
$menu-inverse-image-color: rgba(255, 255, 255, 0.375);

$menu-opacity: 0.8;
$hover-menu-opacity: 1.0;
$selected-opacity: 1.0;
$inactive-menu-opacity: 0.5;

$selected-color: rgb(255, 247, 237);
$light-color: rgb(191, 187, 184);
$dark-color: rgb(92, 84, 79);
$header-color:  rgb(88, 79, 76);
$white-color: white;
$action-color: rgb(247, 148, 32);

$field-select: rgba(255, 255, 255, .45);
$field-hover: rgba(255,255,255,0.3);
$inverse-field-select: rgba(248, 205, 85, .24);
$inverse-field-hover: rgba(248, 205, 85, .12);

$loader: url('images/loader.svg');
$cardsmith-logo: url('images/logo.svg');
$board-background: url('images/bkground.png');
$account-background: url('images/grey_v4.png');

$header-height: 70px;



button.action-button {
		position: relative;
		margin: 10px 20px;
		padding: 10px 40px;
		background-color: $action-color;
		border: none;
		outline: none;
		background-image: none;
		font-family: proxima-semibold;
		font-size: .8em;
		color: white;
		letter-spacing: 1px;
		text-transform: uppercase;
		cursor: pointer;
		text-decoration: none;
		-webkit-box-shadow: none;
		box-shadow: none;

	&:disabled {
		opacity: .4;
	}

	&.animate {
		transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	&:active {
		background-color: rgba($action-color, .8);
	}

	&.schoolbus {
		text-shadow: 0px -1px #df851c;
	}

  &.googleBlue {
        background-color: rgba(#4885ed, .8) !important;
  }
}


.dialog-button {
	display: inline-block;
    padding: 10px 15px;
    background: $menu-image-color;
    border: 0;
    cursor: pointer;
    font-family: 'proxima-semibold';
    font-size: .8em;
    color: rgb(255, 255, 255);
	letter-spacing: .5px;
	text-transform: uppercase;
    outline: 0;

    &:not(.disabled):hover {
		background: $menu-image-hover;
	}
  &.schoolbus {
     background-color: $action-color;
  }

	&.disabled {
		opacity: 0.4;
	}
}
